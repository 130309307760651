import React from 'react'
import "./Vision.css";

function Vision() {
    return (
        <div className="Vision">
            <div className="HeadingVision">
               <h1>OUR VISION</h1>
            </div>
           <p>We are living in an era of Machine Learning/Artificial Intelligence revolution where the world is focusing on building smart solutions to solve both solved and unsolved problems. ML-Ai community believes, we can make the future bright by focusing on two things.

​

We observed the industry is far behind the academic research hence it takes a lot of time to make use of academic research. Few of the times, academic research adoption takes more time because of multiple constraints like the value in the market demand etc but many time we are not able to utilize the full potential because of less common platforms between industry researchers and academic researchers. ML-Ai is designed to work as a bridge between academic researchers and industry.

Making People Smart ML-Ai community will have people from beginner to experts and objective will be to cross learn and lead to a better future. ML-Ai Experts will act like a Professor to other people in the community. Professors will help others in their projects, doing code reviews helping them with the right researcher papers.</p> 
        </div>
    )
}

export default Vision
