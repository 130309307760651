import React, {useEffect} from 'react'
import CardItem from './CardImageItem.js'
import Aos from "aos";
import "aos/dist/aos.css";

function Whatwe() {
    useEffect(() => {
        Aos.init({
          offset:250,duration:900,startEvent: 'load'});    
        }, [])
    return (
        <div>
        <div className='cards'>
        <h1>WHAT WE DO</h1>
        <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
        <div data-aos="fade-down">
              <CardItem
              src='https://cdn.dribbble.com/users/19849/screenshots/13934663/media/f55bf923be38e4fc1b77b53b51a51e07.png'
              text="Community :Join the fastest growing community" />

              </div>
        <div data-aos="fade-down"><CardItem
              src='https://cdn.dribbble.com/users/33385/screenshots/7520253/media/9abfd2e03251c98cb5340a677ac219f1.png'
              text="Research is the backbone of development" /></div>
        <div data-aos="fade-down"><CardItem
              src='https://cdn.dribbble.com/users/131231/screenshots/7232848/media/a1432d10a861151492f098ff735243cc.png'
              text="Training: a chisel can break a mountain if properly trained" /></div>
        <div data-aos="fade-down"><CardItem
              src='https://cdn.dribbble.com/users/3668243/screenshots/6766669/icons-5.jpg'
              text="Consulting: its better to consult first and then execute" /> </div>
        </ul>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Whatwe
