import React, { useEffect } from 'react'
import CardItem from "./CardImageItem.js"; 
import Aos from "aos";
import "aos/dist/aos.css";

function Projects() {
  useEffect(() => {
Aos.init({
  offset:250,duration:900,startEvent: 'load'});    
}, [])
    return (
            <div className='cards'>
      <h1>ML-AI community open source projects</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          
          <div data-aos="fade-down" onClick={(e) => {
      e.preventDefault();
      window.location.href='https://docs.google.com/document/d/1wt07i0ydSVcZ5rT3TzTrBDWlz-JCwTAUGJecIhIfTrs/edit';
      }}>
            <CardItem
              src='https://www.blumeglobal.com/wp-content/uploads/2018/11/NLP-image.jpg'
              text="check our projects on NLP through this Doc file link" />
          </div>
          
          
          <div data-aos="fade-down" onClick={(e) => {
      e.preventDefault();
      window.location.href='https://docs.google.com/document/d/1wt07i0ydSVcZ5rT3TzTrBDWlz-JCwTAUGJecIhIfTrs/edit';
      }}><CardItem
              src='https://i.imgur.com/V6uxcpY.jpg'
              text='check our projects on COMPUTER VISION through this Doc file link'
            />
          </div>
        </ul>


        <ul className='cards__items'>
          <div data-aos="fade-down" onClick={(e) => {
      e.preventDefault();
      window.location.href='https://docs.google.com/document/d/1wt07i0ydSVcZ5rT3TzTrBDWlz-JCwTAUGJecIhIfTrs/edit';
      }}><CardItem
              src='https://miro.medium.com/max/624/1*qc2AIj9eqdHq7QQBjFho7A.png'
              text='check our projects on REINFORCEMENT
LEARNING through this Doc file link'
            />
            </div>
          
          <div data-aos="fade-down" onClick={(e) => {
      e.preventDefault();
      window.location.href='https://docs.google.com/document/d/1wt07i0ydSVcZ5rT3TzTrBDWlz-JCwTAUGJecIhIfTrs/edit';
      }}><CardItem
              src='https://s3.amazonaws.com/cosaction-prod/public/content/images/1902/1902_big.?1546596729'
              text="We are open to NEW CHALLENGES,come on Sumbit your proposal"
            />
        </div>
          </ul>
        </div>
      </div>
    </div>
    )
}

export default Projects
