import React, { useEffect } from 'react';
import './Cards.css';
import CardItem from './CardItem';
import Aos from "aos";
import "aos/dist/aos.css";

function Cards() {
  useEffect(() => {
    Aos.init({
      offset:250,duration:900,startEvent: 'load'});    
    }, [])
  return (
    <div className='cards'>
      <h1>Check out some of our EPIC Collections</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <div data-aos="fade-down">
            <CardItem
              src='https://www.youtube.com/watch?v=iI4ianiLV5U'
              text='
QMeFree | Indian Banks | How will they use it? | Human-Centred Design | Design user interview'
              label='Discussion'
            /></div>

            <div data-aos="fade-down"><CardItem
              src='https://www.youtube.com/watch?v=mDPPg3DQD7c'
              text='QMeFree | Government | UP PCL | How will they use it? | Human-Centred Design | Design user interview'
              label='design'
            /></div>
          </ul>
          
          <ul className='cards__items'>
          <div data-aos="fade-down"><CardItem
              src='https://www.youtube.com/watch?v=Md6pa1lpzAA'
              text='How to build the right DATA SCIENCE RESUME? Tips & Advices'
              label='Tips'
            /></div>
            
            <div data-aos="fade-down"><CardItem
              src='https://www.youtube.com/watch?v=tav1b1jgLzo'
              text='Problem Solving Interview Series | Problem #1 | Sum of Bits Difference'
              label='Solve them'
            /></div>
            
            <div data-aos="fade-down"><CardItem
              src='https://www.youtube.com/watch?v=5MGOSl-bZ8s&list=PLpmJg4Fva_HuK5Ff09iCYNyoUV4o7LNep'
              text='Happy Sharing Series Chapter#1 [Interview with Adam]'
              label='Sharing'
            /></div>
            
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;