
import React from 'react';
import Slider from 'infinite-react-carousel';
import "./Testimonial.css";

function Testimonial() {
  
    const settings =  {
      arrows: false,
      arrowsBlock: false,
      autoplay: true,
      dots: true,
      duration:200,
      autoplaySpeed: 2000
    }
    return (
      <div className="Testi">
      <h1>WHAT PEOPLE SAY</h1>
      
        <Slider { ...settings }>
          <div className="name">
          <h2>Ayushi Tiwari</h2>
            <p>"Superb Mini Projects Discussions, helped to develop intuition for approaching a data science problems"</p>
            <i class="fas fa-user-edit fa-5x" style={{color:"#A0138E"}}></i>
          </div>
          <div className="name">
          <h2>Kshatresh Saini</h2>
            <p>"I just wanted to share a quick note and let you know that you guys do a really good job. I'm glad I decided to work with you."</p>
            <i class="fas fa-laugh-beam fa-5x" style={{color:"#A0138E"}}></i>
          </div>
          <div className="name">
          <h2>Ritu Sharma</h2>
            <p>"Got a great insight and mentorship for developing my first NLP Model"</p>
            <i class="far fa-thumbs-up fa-5x" style={{color:"#A0138E"}}></i>
          </div>
        </Slider>
        </div>
    );
  }
  export default Testimonial;
