import './App.css';
import { BrowserRouter as Router,Switch,Route } from "react-router-dom";
import Home from './components/Pages.js/Home';
import Navbar from "../src/components/NavBar"


function App() {
  return (
    <>
      <Router>
      <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
