import React ,{useEffect} from 'react'
import "../../App.css";
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Projects from '../Projects';
import Testimonial from '../Testimonial';
import Vision from '../Vision';
import Whatwe from '../Whatwe';
import Aos from "aos";
import "aos/dist/aos.css";
import Founders from '../Founders';
import Experts from '../Experts';
import Footer from "../footer.js";





function Home() {
    useEffect(() => {
        Aos.init({
          offset:150,duration:800,startEvent: 'load'});    
        }, [])
    return (
        <>
        <HeroSection />
        <Cards />
        <div style={{background:"white"}}>
        <div data-aos="fade-down">
        <img src='/photos/ima.jpeg' alt="image1" style={{objectFit:"contain",width:"100%"}} />
        </div>
        </div>
        <Projects />
        <Vision />
       <Whatwe />
       <Testimonial />
       <div style={{background:"white"}}>
       <div data-aos="fade-down">
       <img src="/photos/img2.jpeg" alt="image2" style={{objectFit:"contain",width:"100%"}} />
       </div>
       </div>
       <Founders />
       <Experts />
       <Footer />
        </>
    )
}

export default Home
