import React,{useEffect}from 'react';
import "./Founders.css";
import Aos from "aos";
import "aos/dist/aos.css";

function Founders() {
    useEffect(() => {
        Aos.init({
          offset:150,duration:800,startEvent: 'load'});    
        }, [])
    return (
        <div className="Founder">
            <div className ="HeadingFounder">
                <h1>Founder's Note</h1>
            </div>
            <div className="aboutFounder">
                <div data-aos="fade-down" className="founderInfo" >
                    <h4><strong> Krishna Kumar Tiwari | Founder ML-Ai Community
 40 Under 40 Data Scientist</strong></h4>
<p> Krishna Kumar Tiwari is an Architect at InMobi Group. A strong believer in making the future bright by building intelligent platforms. He has consulted and worked with several startups as well as Fortune 500 companies like Samsung,Oracle.
He is also actively working on building ML-Ai community to bridge the gap between academics and industry.</p><h4> 
<strong>Keep Learning & Keep Sharing! </strong></h4>
                </div>
                <div data-aos="flip-up" className="founderPic">
                    <img src="https://pbs.twimg.com/profile_images/1255409816698322946/cLUQe4Jp.jpg" style={{borderRadius:"50%",width:"250px"}}/>
                </div>
            </div>
        </div>
    )
}

export default Founders

