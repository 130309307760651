import React,{useEffect} from 'react'
import CardItem from "./CardImageItem"
import Aos from "aos";
import "aos/dist/aos.css"; 
import "./Experts.css";

function Experts() {
    useEffect(() => {
        Aos.init({
          offset:150,duration:800,startEvent: 'load'});    
        }, [])
    return (
        <div className="Expert">
        <div className="ExpertHeading">
            <h1>Experts</h1>
        </div>
        <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          
          <div data-aos="fade-down" onClick={(e) => {
      e.preventDefault();
      window.location.href='https://alexeygrigorev.com';
      }}>
            <CardItem
              src='https://avatars0.githubusercontent.com/u/875246?s=400&u=067d8c7e8493143a8d047f706772fb1bcaccaea8&v=4'
              text="Alexey Grigorev | Lead Data Scientist Berlin" />
          </div>

          <div data-aos="flip-up" onClick={(e) => {
      e.preventDefault();
      window.location.href='https://www.youtube.com/khanradcoder';
      }}><CardItem
              src='https://miro.medium.com/fit/c/1360/1360/2*6HXOp61L4cgB_vY3tkNHzw.jpeg'
              text='Adam Eubanks | Udemy Instructor 
United States'
            />
            </div>
          </ul>
        </div>
      </div>
      </div>
        </div>
        
    )
}

export default Experts
