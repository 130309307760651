import React from "react";
import "./footer.css";

function footer(){
    return(<div className="footerover">
        <div className="footer">
            Made with ❤ from ML AI Community
        </div>
        {/* <div>
            Copyright © formulateAI team
        </div> */}
        </div>
    )
}
export default footer;