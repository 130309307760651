import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
             <i class="fas fa-people-carry"></i>

             {/* ML-AI  */}
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link to={{  pathname: "https://github.com/ML-AI-Community/ml-ai" }} onClick={closeMobileMenu} className='nav-links' target="_blank">Projects</Link>
            </li>
            <li className='nav-item'>
            <Link to={{  pathname: "https://docs.google.com/forms/d/e/1FAIpQLSdjZcxsWYefLF2Bcok0a1PhyNaV1clDXNzJH217RQrheOjE9Q/viewform" }} onClick={closeMobileMenu} className='nav-links' target="_blank">Proposal</Link>
            </li>
            <li>
              <Link to={{  pathname: "https://www.linkedin.com/groups/10494159/" }} className='nav-links-mobile' onClick={closeMobileMenu} target="_blank">Join Us</Link>
            </li>
          </ul>
          {button && <Button buttonStyle='btn--outline' onClick={(e) => {
      e.preventDefault();
      window.location.href='https://www.linkedin.com/groups/10494159/';
      }}>Join Us</Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;